import PackerLayout from "Layout"
import { Navigate } from "react-router-dom"

import Pages from "./Pages"

const AdminRoutes = [
  {
    path: "",
    element: <PackerLayout />,
    children: [
      {
        path: "",
        element: <Pages.CourierList />,
      },
      {
        path: "courier/:id",
        element: <Pages.CourierView />,
      },
      {
        path: "courier/:id/delivery",
        element: <Pages.CourierDeliveryCheck />,
      },
      {
        path: "list",
        element: <Pages.Lists />,
      },

      {
        path: "list/:id",
        element: <Pages.ListView />,
      },
      {
        path: "list/:id/attach-orders",
        element: <Pages.AttachCurier />,
      },

      {
        path: "storage",
        element: <Pages.Storage />,
      },

      {
        path: "packing",
        element: <Pages.Packing />,
      },
      {
        path: "check-history",
        element: <Pages.CheckHistory />,
      },

      {
        path: "waybill/:id/accept-orders",
        element: <Pages.AccpetOrders />,
      },

      {
        path: "transfer-orders",
        element: <Pages.TransferOrders />,
      },
    ],
  },
  {
    path: "*",
    element: <Navigate to="/" />,
    children: [],
  },
]

if (process.env.REACT_APP_MODE === "development") {
  AdminRoutes.push()
}

export default AdminRoutes
