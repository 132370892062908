const { lazy } = require("react")

const Pages = {
  Signin: lazy(() => import("Pages/Auth/Signin")),
  RestorePassword: lazy(() => import("Pages/Auth/RestorePassword")),

  AttachCurier: lazy(() => import("Pages/AttachCurier")),

  Lists: lazy(() => import("Pages/Lists")),
  ListView: lazy(() => import("Pages/Lists/View")),

  Storage: lazy(() => import("Pages/Storage")),

  CourierList: lazy(() => import("Pages/Courier")),
  CourierView: lazy(() => import("Pages/Courier/View")),
  CourierDeliveryCheck: lazy(() => import("Pages/DeliveryCheck")),

  Packing: lazy(() => import("Pages/Packing")),
  CheckHistory: lazy(() => import("Pages/CheckHistory")),

  AccpetOrders: lazy(() => import("Pages/AcceptOrders")),

  TransferOrders: lazy(() => import("Pages/TransferOrders")),
}

export default Pages
